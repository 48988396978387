import $ from 'jquery'

$(document).on("turbolinks:load", () => {
  const $sliders = $(".fakeSlider");
  if ($sliders.length == 0)
    return;

  $(".fakeSlider-controls-prev, .fakeSlider-controls-next").click(() => {
    const scrollTop = $(window).scrollTop()
    $(document).one("turbolinks:load", () => {
      $(window).scrollTop(scrollTop)
    })
  })
})
